/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import _ from "lodash";
import React from "react";
import {  Filters, ResultCount } from "./components";
import * as h from "./helpers";
import { State } from "./types";

export type SessionsFeedProps = UI.BoxProps & {
  state: State;
};

const SessionsFeed: React.FC<SessionsFeedProps> = ({ state, ...props }) => {
  const { loading, more, fetchMore, items } = state;
  const [loadingMore, setLoadingMore] = React.useState(false);

  const handleLoadMore = async () => {
    if (!loadingMore && more) {
      setLoadingMore(true);
      await fetchMore();
      setLoadingMore(false);
    }
  };

  return (
    <>
      {loading ? (
        <UI.Loading key="loading" />
      ) : (
        <>
          {items.length === 0 && (
            <UI.Box key="empty">
              <UI.Typography variant="h5" color="neutral.main">
                No sessions found 🤔
              </UI.Typography>
              <UI.Typography variant="body1" color="neutral.main">
                Try removing or changing some of your filters
              </UI.Typography>
            </UI.Box>
          )}
          {_.map(h.groupByDay(items), ([date, items]) => (
            <UI.Box key={date.getTime()} sx={{ pt: 1, pb: 2 }}>
              <UI.Typography variant="h5" color="neutral.main">
                {u.time.formatDay(date)}
              </UI.Typography>
              {items.map((item) => (
                <UI.Box key={item.id} sx={{ mb: 2 }}>
                  <UI.ListingCard
                    key={h.identifier(item)}
                    listing={item}
                    sx={{ my: 2 }}
                  />
                </UI.Box>
              ))}
            </UI.Box>
          ))}

          {/* Load More Button */}
          {more && (
            <UI.Box sx={{ textAlign: "center", mt: 2 }}>
              <UI.Button
                variant="text"
                onClick={handleLoadMore}
                disabled={loadingMore}
                sx={{
                  fontSize: "30px",
                  textTransform: "uppercase",
                  fontStyle: "italic",
                }}
              >
                {loadingMore ? "Loading..." : "Load More"}
              </UI.Button>
            </UI.Box>
          )}
        </>
      )}
    </>
  );
};

//@ts-ignore
SessionsFeed.ResultCount = ResultCount;
//@ts-ignore
SessionsFeed.Filters = Filters;

export default SessionsFeed as typeof SessionsFeed & {

  Filters: typeof Filters;
  ResultCount: typeof ResultCount;
};
